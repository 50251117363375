import {
    initFeatureTogglesForToggleGroups
} from 'common';

const env = process.env.NODE_ENV === 'development' || process.env.TARGET_ENV === 'dev'
    ? 'dev'
    : 'prod';

initFeatureTogglesForToggleGroups(
    [env]
);
