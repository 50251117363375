export const featureToggleDefinitionsById = {
    homePageReports: {
        name: 'Home Page Reports',
        description: 'Display the Home Page Reports (currently there is only the work-in-progress Distribution Study page)'
    },
    showQueries: {
        name: 'Query Tab Container',
        description: 'Display the Query Tab Container beneath relevant metrics and charts which displays the SQL code used to fetch what you are seeing'
    },
    deploymentDebugMode: {
        name: 'Deployment Debug Mode',
        description: 'Show additional UI in deployment to assist in debugging model results'
    },
    experimentalOvergoalsCharts: {
        name: 'Experimental Overgoals Charts',
        description: `Add an overgoals-grouped SingleStackedBarChart to the Incident Volume report
and an overgoals-grouped CategoricalBarChart to the Overgoals report`,
    },
    risk: {
        name: 'Risk app',
        description: 'Show the CRA application'
    },
    deploymentAuditor: {
        name: 'Deployment Auditor',
        description: 'Show the Deployment Auditor application'
    },
    riskUrlStateLogging: {
        name: 'Risk app URL state logging',
        description: 'Log long names to the console for risk app URL state',
    },
    newHqChartImageExports: {
        name: 'New HQ Chart Image Exports',
        description: 'Enable a button on HQ charts that exports a PNG using a new design with padding and better formatting',
    },
    newHqHtmlCharts: {
        name: 'New HQ Charts',
        description: 'Replace the HQ charts with new HTML-based designs that have nicer fonts and formatting',
    },
    unitCapabilities: {
        name: 'Unit Capabilities',
        description: 'Show the new Unit Capabilities UI in Deployment',
    },
    hqChartForceWidth: {
        name: 'HQ Chart Force Width',
        description: 'Force the width of the HQ charts to be 1920 pixels. This is only intended to be used for exporting images.',
    },
    responseBoundaries: {
        name: 'Response Boundaries',
        description: 'Enable the response boundary selector, which constrains deployment responses to stay within the chosen boundaries'
    }
};

export type ToggleId = keyof typeof featureToggleDefinitionsById;

export type ToggleGroup = 'dev' | 'prod' | 'beta';

export type ToggleGroupDefinition = {
    label: string,
    description: string,
    toggles: ToggleId[],
}
export const defaultFeatureToggleValues: Record<ToggleGroup, ToggleGroupDefinition> = {
    // Toggles that will be used in local and dev envs
    dev: {
        label: 'Development',
        description: 'Toggles that will be used by default in development environments',
        toggles: ['riskUrlStateLogging']
    },
    // Toggles that will be used in staging and production envs
    prod: {
        label: 'Production',
        description: 'Toggles that will be used by default in production environments',
        toggles: [],
    },
    beta: {
        label: 'Internal Beta Features',
        description: 'These features are almost ready for release and are being tested internally. All Darkhorse staff will have them enabled by default, but you can override individual features if necessary.',
        toggles: [
            'newHqHtmlCharts',
            'newHqChartImageExports'
        ]
    },
};
