import { until } from '@vueuse/core';
import _ from 'lodash';
import { toValue } from 'vue';
import { NavigationGuardNext, RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';
import { Tenant, TenantApp, useTenantListStore } from '@/stores/TenantListStore/TenantListStore';

function buildAppUrl(options: {
    appName?: string,
    tenantId?: string,
    appId?: string
}): RouteLocationNamedRaw {
    const pickerRouteLookup = {
        Home: 'HqDatabasePicker',
        Dashboards: 'DashboardsDatabasePicker',
        Diagnostics: 'DiagnosticsDatabasePicker',
        Deployment: 'list',
        Risk: 'RiskTenantPicker'
    };

    const {
        appName = 'Home',
        tenantId,
        appId
    } = options;

    if (!tenantId) {
        return {
            name: pickerRouteLookup[appName] ?? pickerRouteLookup.Home
        };
    }

    if (appName === 'Diagnostics') {
        if (appId) {
            return {
                name: 'DiagnosticsApp',
                params: {
                    tenant: tenantId,
                    diagnostics: appId
                }
            };
        }
        return {
            name: 'DiagnosticsPicker',
            params: {
                tenant: tenantId
            }
        };
    }

    if (appName === 'Dashboards') {
        if (appId) {
            return {
                name: 'DashboardAppMain',
                params: {
                    tenant: tenantId,
                    dashboard: appId
                }
            };
        }
        return {
            name: 'DashboardPicker',
            params: {
                tenant: tenantId
            }
        };
    }

    const appRouteLookup = {
        Home: 'HqDashboard',
        Deployment: 'DeploymentApp',
        Risk: 'RiskApp'
    };

    return {
        name: appRouteLookup[appName] ?? appRouteLookup.Home,
        params: {
            tenant: tenantId
        }
    };
}

function getAutoselectTenant(tenants: Tenant[], tenantId?: string) {
    // First, figure out which tenant to use.
    let tenant: Tenant | undefined;
    if (tenantId) {
        tenant = tenants?.find(t => t.tenantId === tenantId && t.available);
    }
    if (!tenant && tenants?.length === 1) {
        tenant = tenants[0];
    }
    return tenant;
}

function getAutoselectApp(tenant: Tenant, options: {
    appName?: string;
    allowOtherApps?: boolean;
}): TenantApp | undefined {
    const app = tenant.apps.find(a => a.name === options?.appName && a.available);
    if (app) {
        return app;
    }
    if (options.allowOtherApps) {
        return tenant.apps.find(a => a.available);
    }
    return undefined;
}

/**
 * Find a valid route to send the user to based on their level of access. Tries to match as closely as possible
 * to what was initially requested in the URL, and configurably falls back on nearby routes.
 *
 * Some key examples:
 *
 * If a user accesses a tenant picker, but they only have access to one tenant, redirect them to that tenant
 *
 * If a user accesses a tenant, and they only have access to one app, redirect them to that app
 *
 * If a user accesses a multi-url app, and they only have access to one URL, redirect them directly to that URL
 *
 * These behaviours compose as well. If a user who only has access to one Diagnostics app, and they try to access
 * the HQ tenant picker, they should immediately be sent directly to that Diagnostics app.
 *
 * i.e.:
 * ```
 * getValidRouteForUser([...], {
 *     appName: 'Home',
 *     allowUserApps: true
 * })
 * ```
 * should return a Diagnostics app route in the situation described above.
 *
 * @param tenants A list of Tenants returned by the API
 * @param options.tenantId (optional) A tenant ID to use if possible
 * @param options.appName (optional) An app to preferentially use if possible
 * @param options.allowOtherApps (optional) Set to true to allow us to return a route from another app
 * @param options.allowListView (optional) Set to true to allow us to return a tenant list route
 * @returns A vue-router route location or undefined if none could be found
 */
export function getValidRouteForUser(tenants: Tenant[], options: {
    tenantId?: string;
    appName?: string;
    allowOtherApps?: boolean;
    allowListView?: boolean;
}): RouteLocationNamedRaw | undefined {
    const tenant = getAutoselectTenant(tenants, options.tenantId);
    if (!tenant) {
        if (options.allowListView) {
            return buildAppUrl({ appName: options.appName });
        }
        return undefined;
    }
    const app = getAutoselectApp(tenant, options);

    if (app?.__typename === 'MultiUrlApp') {
        const apps = app.apps.filter(a => a.available);

        const appId = apps.length === 1 ? apps[0].id : undefined;

        return buildAppUrl({
            appName: app.name,
            tenantId: tenant.tenantId,
            appId
        });
    }

    return buildAppUrl({
        tenantId: tenant.tenantId,
        appName: app?.name ?? options.appName,
    });
}

/**
 * Construct a vue-router middleware that will check the current user's available tenants and apps,
 * and redirect them to an appropriate app.
 *
 * @param options.appName (optional) The app of the route this middleware is mounted at
 * @param options.allowOtherApps (optional) True to allow us to redirect to another app, false to mount the requested app regardless of validity
 * @returns A vue-router beforeEnter middleware
 */
export function redirectToValidRouteForUser(options: {
    appName?: string,
    allowOtherApps?: boolean
}) {
    return async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        // !!! VERY IMPORTANT !!!
        // This middleware cannot reference any stores or composables that depend on the router in any way, otherwise
        // we can easily create an infinite loop which will cause the app to freeze. This is because any attempt to
        // use route params (such as the tenant ID) will remain undefined until the middleware resolves.

        // The TenantListStore is safe because it only depends on the current user.
        const tenantListStore = useTenantListStore();
        const getTenantList = () => tenantListStore.tenantsQueryResult.result;

        // Getting the tenant ID from middleware params is safe
        const tenantId = to.params.tenant as string | undefined;

        // The TS types for until() are inaccurate when the value is an array
        // See: https://github.com/vueuse/vueuse/issues/3740
        // The returned value will alway have a .toMatch() method, so we use that instead
        // of .toBeUndefined() which doesn't actually exist at runtime for array values
        await until(getTenantList).not.toMatch(t => _.isNil(t));
        const redirectRoute = getValidRouteForUser(
            // Not-null assertion is safe because the until() call above won't resolve until this value
            // is defined
            toValue(getTenantList)!,
            {
                ...options,
                tenantId
            }
        );

        if (redirectRoute && (
            redirectRoute.name !== to.name
            || !_.isEqual(redirectRoute.params, to.params)
        )) {
            next(redirectRoute);
        } else {
            next();
        }
    };
}
