import assert from 'assert';
import {
    ToggleGroup,
    ToggleId,
    defaultFeatureToggleValues,
    featureToggleDefinitionsById,
} from './toggleDefinitions';
import { FeatureToggles, ToggleInfo, initFeatureToggles } from './toggles';

let toggles: FeatureToggles<ToggleId, ToggleGroup>;

export function initFeatureTogglesForToggleGroups(toggleGroups: ToggleGroup[]) {
    toggles = initFeatureToggles(
        featureToggleDefinitionsById,
        defaultFeatureToggleValues,
        toggleGroups
    );
}

export function featureIsEnabled(id: ToggleId): boolean {
    assert(toggles, 'Feature toggles not initialized');
    return toggles.featureIsEnabled(id);
}

export function getFeatureToggles(): Record<ToggleId, boolean> {
    assert(toggles, 'Feature toggles not initialized');
    return toggles.getFeatureToggles();
}

export function getFeatureTogglesInfo(): Record<ToggleId, ToggleInfo> {
    assert(toggles, 'Feature toggles not initialized');
    return toggles.getFeatureTogglesInfo();
}

export function saveUserFeatureToggleOverrides(overrides: Record<ToggleId, boolean>) {
    assert(toggles, 'Feature toggles not initialized');
    toggles.saveUserFeatureToggleOverrides(overrides);
}

export function applyToggleGroup(toggleGroup: ToggleGroup, enable = true) {
    assert(toggles, 'Feature toggles not initialized');
    toggles.applyToggleGroup(toggleGroup, enable);
}

export function getFeatureToggleSets() {
    assert(toggles, 'Feature toggles not initialized');
    return toggles.getToggleSets();
}
