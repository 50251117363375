import { ResultOf } from '@graphql-typed-document-node/core';
import _ from 'lodash';
import { defineStore, storeToRefs } from 'pinia';
import { MaybeRefOrGetter, computed } from 'vue';
import { useCombineQueries, useTransformQueryResult } from '@/composables/api/helpers';
import { useConfigurableQuery } from '@/composables/api/useConfigurableQuery';
import { ListTenantsDocument } from '@/generated/graphql-operations';
import { useApiClient } from '@api-client/composables';
import { useUserStore } from '../UserStore';
import { getAbsoluteLogoUrl } from './helpers';

type TenantResult = ResultOf<typeof ListTenantsDocument>['listTenantApis'][number];
export type Tenant = TenantResult;
export type TenantApp = Tenant['apps'][number];

export const useTenantListStore = defineStore('TenantListStore', () => {
    const { currentUser } = storeToRefs(useUserStore());
    const { apiClient } = useApiClient();

    // Convenience useConfigurableQuery wrapper
    function useQueryApiHostTenants(apiHost: MaybeRefOrGetter<string>) {
        return useConfigurableQuery({
            user: currentUser,
            query: ListTenantsDocument,
            apiHost
        });
    }

    const apiHosts = [
        apiClient.value.primaryRegion.apiHost,
        ...apiClient.value.additionalRegions.map(region => region.apiHost)
    ];

    const tenantsQueryResult = useTransformQueryResult(
        useCombineQueries(
            useQueryApiHostTenants(apiClient.value.primaryRegion.apiHost),
            ...apiClient.value.additionalRegions.map(region => useQueryApiHostTenants(region.apiHost))
        ),
        (results): (Tenant & { apiHost: string })[] => results
            .flatMap((result, i) => (result?.listTenantApis ?? []).map(tenant => ({
                apiHost: apiHosts[i],
                ...tenant,
                logo: tenant.logo ? getAbsoluteLogoUrl(tenant.logo, apiHosts[i]) : tenant.logo
            })))
    );

    const tenantsById = computed(() => {
        if (!tenantsQueryResult.value.result) {
            return undefined;
        }
        return _.keyBy(tenantsQueryResult.value.result, t => t.tenantId);
    });

    function getTenantInfo(tenantId: string) {
        return tenantsById.value?.[tenantId];
    }

    return {
        tenantsQueryResult,
        getTenantInfo
    };
});
