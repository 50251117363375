import { FEATURE_TOGGLES_LOCAL_STORAGE_KEY, applyToggleGroup } from 'common';
import { type Ref, computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useUserStore } from '@/stores/UserStore';

export function useFeatureToggles(): { togglesHaveChanged: Ref<boolean> } {
    const togglesHaveChanged = ref(false);

    const userStore = useUserStore();
    const userIsAdmin = computed(() => userStore.userIsAdmin);

    watch(userIsAdmin, (isAdmin, wasAdmin) => {
        if (isAdmin && !wasAdmin) {
            applyToggleGroup('beta');
        }
    }, { immediate: true });

    function update(event: StorageEvent) {
        if (event.key === FEATURE_TOGGLES_LOCAL_STORAGE_KEY) {
            togglesHaveChanged.value = true;
        }
    }

    onMounted(() => window.addEventListener('storage', update));
    onUnmounted(() => window.removeEventListener('storage', update));

    return {
        togglesHaveChanged,
    };
}
