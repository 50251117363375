import { lateResolvePromise } from 'common';
import _ from 'lodash';
import { DeepReadonly, Ref, computed, readonly, ref, watch, watchEffect } from 'vue';

export type QueryObserverState<Result> = {
    result?: Result
    error?: unknown;
    isLoading: boolean;
};

export function waitUntilResult<T>(query: Ref<QueryObserverState<T>>): Promise<T> {
    const p = lateResolvePromise<T>();

    watchEffect(() => {
        const {
            result,
            error
        } = query.value;

        if (result) {
            p.resolve(result);
        }
        if (error) {
            p.reject(error);
        }
    });

    return p;
}

/**
 * Takes a ref that may be undefined and returns a ref that's always defined and always
 * a promise.
 *
 * We use this primarily for an async process that might not be able to start immediately. We
 * want dependent functionality to be able to await a promise that will resolve with the first result.
 */
export function useImmediatelyDefinedPromise<T>(rawRef: Ref<T | Promise<T> | undefined>): Readonly<Ref<Promise<DeepReadonly<T>>>> {
    const initialPromise = lateResolvePromise<T>();
    let initialPromiseHasResolved = false;
    const internalRef = ref<Promise<T>>(initialPromise);

    watch(() => rawRef.value, (value) => {
        if (value) {
            if (!initialPromiseHasResolved) {
                initialPromise.resolve(value);
                initialPromiseHasResolved = true;
            } else {
                internalRef.value = Promise.resolve(value);
            }
        }
    }, { immediate: true, flush: 'sync' });

    return readonly(internalRef);
}

export function useCombineQueries<T extends Ref<QueryObserverState<any>>[]>(
    ...queries: T
): Ref<QueryObserverState<{ [K in keyof T]: T[K]['value']['result'] }>> {
    const queryResults = computed(() => {
        const firstError = _.find(queries, q => !_.isNil(q.value.error))?.value.error;
        if (firstError) {
            return {
                error: firstError,
                isLoading: false
            };
        }

        if (_.some(queries, q => q.value.isLoading)) {
            return {
                isLoading: true
            };
        }

        const results: any = queries.map(q => q.value.result);

        return {
            result: results,
            isLoading: false
        };
    });

    return queryResults;
}

export function useTransformQueryResult<Input, Output>(
    input: Ref<QueryObserverState<Input>>,
    transformer: (input: Input) => Output
): Ref<QueryObserverState<Output>> {
    return computed(() => {
        const {
            result,
            ...status
        } = input.value;
        if (result) {
            try {
                return {
                    ...status,
                    result: transformer(result)
                };
            } catch (err) {
                return {
                    isLoading: false,
                    error: err
                };
            }
        }
        return status;
    });
}

export function useThrowQueryErrors<T>(query: Ref<QueryObserverState<T>>) {
    watch(query, (status) => {
        if (status.error) {
            throw status.error;
        }
    });
}
